import React from 'react';
import {Button, Stack, TextField} from '@shopify/polaris';
import {OnboardingCard} from '@shopify/channels-ui';

const OnboardingVenndrCard = ({state, venndrOnboardingToken, refetch}) => {
  const [loading, setLoading] = React.useState(false);
  const handleButtonClick = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 600);
    refetch();
  };

  return (
    <OnboardingCard title="Connect your Venndr store" state={state} sectioned>
      {state === 'completed' ? (
        <p>You have connected your Venndr store.</p>
      ) : (
        <Stack vertical>
          <p>
            Copy and paste the below token into the Apps &gt; Shopify section of
            your Venndr account.
          </p>
          <TextField
            label="Token"
            disabled
            autoComplete="off"
            value={venndrOnboardingToken}
          />
          <Stack distribution="trailing">
            <Button loading={loading} primary onClick={handleButtonClick}>
              Check store connection
            </Button>
          </Stack>
        </Stack>
      )}
    </OnboardingCard>
  );
};

export default OnboardingVenndrCard;
