import React from 'react';
import {
  Stack,
  CalloutCard,
  Page,
  Layout,
  Card,
  SkeletonDisplayText,
  SkeletonBodyText,
  TextField,
} from '@shopify/polaris';

const VenndrConnect = () => {
  const [error, setError] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [loadingSubmission, setLoadingSubmission] = React.useState(false);
  const [alreadyConnected, setAlreadyConnected] = React.useState(false);
  const [tokenValue, setTokenValue] = React.useState('');

  const storeId = new URL(location).searchParams.get('store_id');

  React.useEffect(() => {
    fetch(`/connected/${storeId}`)
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setAlreadyConnected(response);
      });
  }, []);

  if (loading) {
    return (
      <Page titleHidden narrowWidth>
        <Layout>
          <Layout.Section>
            <SkeletonDisplayText />
          </Layout.Section>
          <Layout.Section>
            <Card sectioned>
              <SkeletonBodyText lines={2} />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }

  const handleAction = () => {
    setLoadingSubmission(true);

    fetch('/connect', {
      method: 'POST',
      body: JSON.stringify({storeId, token: tokenValue}),
      headers: {'Content-Type': 'application/json'},
      compress: true,
    })
      .then((response) => response.json())
      .then((response) => {
        setLoadingSubmission(false);

        if (response?.error === 'INVALID_TOKEN') {
          setError('The token was invalid.');
        } else if (response?.error === 'NO_VENNDR_API_TOKEN') {
          setError('API token not set. Please contact your account manager.');
        } else {
          setError(undefined);
          setAlreadyConnected(true);
        }
      });
  };

  const state = alreadyConnected ? 'completed' : 'active';

  if (state === 'completed') {
    return (
      <Card title="Shopify store connected" sectioned>
        <p>
          If you have not already, return to Shopify to complete app
          installation.
        </p>
      </Card>
    );
  }

  return (
    <CalloutCard
      title="Input your unique token from Shopify"
      primaryAction={{
        content: 'Verify',
        disabled: !tokenValue || tokenValue === '' || alreadyConnected,
        loading: loadingSubmission,
        onAction: handleAction,
      }}
    >
      <Stack vertical>
        <p>
          In order to link your Venndr store with your Shopify store, enter the
          token that is provided during the Shopify App onboarding process.
        </p>
        <TextField
          label="Token"
          autoComplete="off"
          value={tokenValue}
          disabled={loadingSubmission}
          onChange={(v) => {
            setError(undefined);
            setTokenValue(v);
          }}
          error={error}
        />
      </Stack>
    </CalloutCard>
  );
};

export default VenndrConnect;
