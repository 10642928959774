import React from 'react';
import {AccountConnection, Link} from '@shopify/polaris';

const NoAccountConnected = () => {
  return (
    <AccountConnection
      accountName=""
      connected={false}
      title="Venndr Sales Channel"
      action={{
        content: 'Connect',
        url: '/onboarding',
      }}
      details="No store connected"
      termsOfService={
        <p>
          By clicking <strong>Connect</strong>, you agree to accept Venndr’s{' '}
          <Link external url="https://venndr.com/terms-and-conditions/">
            terms and conditions
          </Link>
          . No commission will be charged for the use of this app.
        </p>
      }
    />
  );
};

export default NoAccountConnected;
