import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from './foundation';

const rootEl = document.getElementById('root');
const render = (Component) => {
  ReactDOM.render(<Component />, rootEl);
};

render(Router);
