import React from 'react';
import {Layout, TextContainer, Heading} from '@shopify/polaris';
import {ProductStatusSection} from '@shopify/channels-ui';

const Status = ({availableProductCount, publicationId, domain, appHandle}) => {
  return (
    <React.Fragment>
      <Layout.Section>
        <TextContainer>
          <Heading>Product status</Heading>
        </TextContainer>
      </Layout.Section>

      <Layout.Section>
        <ProductStatusSection
          manageAction={{
            content: 'Manage availability',
            external: true,
            url: `https://${domain}/admin/bulk?resource_name=Product&edit=publications.${publicationId}.published_at`,
          }}
          summary={`${availableProductCount} product(s) are being synced to your Venndr account`}
          productStatuses={[
            {
              badge: {
                children: 'Published',
                status: 'success',
              },
              label: {
                content: `${availableProductCount} products`,
                external: true,
                url: `https://${domain}/admin/products?selectedView=all&published_status=${appHandle}%3Avisible`,
              },
            },
          ]}
        />
      </Layout.Section>
    </React.Fragment>
  );
};

export default Status;
