import React from 'react';
import {AccountConnection, Link} from '@shopify/polaris';
import {gql, useMutation} from '@apollo/client';

const DISCONNECT_VENNDR_STORE_MUTATION = gql`
  mutation DisconnectVenndrStore {
    disconnectVenndrStore {
      id
      onboardingInfoCompleted
      onboardingCompleted
      venndrStoreName
      venndrStoreUrl
      venndrStoreId
      connectedToVenndr
    }
  }
`;

const AccountConnected = ({venndrStoreName, venndrStoreUrl}) => {
  const [disconnectVenndrStore, {loading}] = useMutation(
    DISCONNECT_VENNDR_STORE_MUTATION,
  );

  const handleAction = () => {
    disconnectVenndrStore();
  };

  return (
    <AccountConnection
      accountName={venndrStoreName}
      connected={true}
      title={
        <React.Fragment>
          <div>{venndrStoreName}</div>
          <div>
            <Link external url={venndrStoreUrl}>
              {venndrStoreUrl}
            </Link>
          </div>
        </React.Fragment>
      }
      action={{
        content: 'Disconnect',
        onAction: handleAction,
        loading,
      }}
      details="Store connected"
    />
  );
};

export default AccountConnected;
