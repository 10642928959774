import React from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {
  LoadingState,
  OnboardingInfoCard,
  OnboardingVenndrCard,
} from './components';
import {OnboardingPage} from '@shopify/channels-ui';
import {useNavigate} from 'react-router-dom';

const checkState = (completedCondition, activeCondition) =>
  completedCondition ? 'completed' : activeCondition ? 'active' : 'disabled';

const ONBOARDING_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminShop {
      id
      onboardingInfoCompleted
      onboardingCompleted
      venndrStoreId
      venndrStoreName
      venndrStoreUrl
      venndrOnboardingToken
      connectedToVenndr
    }
  }
`;

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation CompleteOnboarding {
    completeOnboarding {
      id
      onboardingInfoCompleted
      onboardingCompleted
      venndrStoreId
      venndrStoreName
      venndrStoreUrl
      venndrOnboardingToken
      connectedToVenndr
    }
  }
`;

const Onboarding = () => {
  const {data, loading, refetch} = useQuery(ONBOARDING_PAGE_QUERY);
  const [completeOnboarding, {loading: completeLoading}] = useMutation(
    COMPLETE_ONBOARDING_MUTATION,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.adminShop.onboardingCompleted) {
      navigate('/');
    }
  }, [data]);

  if (loading) {
    return <LoadingState />;
  }

  const handleAction = () => {
    completeOnboarding();
  };

  const {
    adminShop: {onboardingInfoCompleted, venndrStoreId, venndrOnboardingToken},
  } = data;

  return (
    <OnboardingPage
      title="Setup Venndr channel"
      breadcrumb={{
        content: 'Back',
        url: '/',
      }}
      action={{
        content: 'Finish',
        loading: completeLoading,
        disabled: venndrStoreId == null,
        onAction: handleAction,
      }}
    >
      <OnboardingInfoCard
        state={onboardingInfoCompleted ? 'completed' : 'active'}
      />
      <OnboardingVenndrCard
        state={checkState(venndrStoreId, onboardingInfoCompleted)}
        venndrOnboardingToken={venndrOnboardingToken}
        refetch={refetch}
      />
    </OnboardingPage>
  );
};

export default Onboarding;
