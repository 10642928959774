import FeatureImage from './images/venndr.png';
import React from 'react';
import {Layout, Card, EmptyState} from '@shopify/polaris';

const Introduction = () => {
  return (
    <Layout.Section>
      <Card>
        <EmptyState
          heading="Sell your products via a Venndr marketplace"
          imageContained
          image={FeatureImage}
        >
          <p>
            Sell your products via a Venndr marketplace whist continuing to
            manage your products and orders via Shopify.
          </p>
        </EmptyState>
      </Card>
    </Layout.Section>
  );
};

export default Introduction;
