import React from 'react';
import {OnboardingCard, OnboardingInfo} from '@shopify/channels-ui';
import {gql, useMutation} from '@apollo/client';

const COMPLETE_ONBOARDING_INFO_MUTATION = gql`
  mutation CompleteOnboardingInfo {
    completeOnboardingInfo {
      id
      onboardingInfoCompleted
    }
  }
`;

const OnboardingInfoCard = ({state}) => {
  const [completeOnboardingInfo, {loading}] = useMutation(
    COMPLETE_ONBOARDING_INFO_MUTATION,
  );

  const handleAction = () => {
    completeOnboardingInfo();
  };

  return (
    <OnboardingCard title="Before you start" state={state}>
      <OnboardingInfo
        completed={state === 'completed'}
        items={[
          {
            title:
              "You'll need to have a Venndr account with the Venndr Shopify App installed in order to complete onboarding.",
            content: (
              <p>
                Please contact your Venndr account manager if you require
                assistance.
              </p>
            ),
          },
        ]}
        action={{
          content: 'Continue',
          loading,
          onAction: handleAction,
        }}
      />
    </OnboardingCard>
  );
};

export default OnboardingInfoCard;
