import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {
  Card,
  SkeletonBodyText,
  SkeletonPage,
  Link,
  FooterHelp,
  Page,
  Heading,
  TextContainer,
  Layout,
} from '@shopify/polaris';
import AccountConnected from './components/AccountConnected';
import Status from './components/Status';
import NoAccountConnected from './components/NoAccountConnected';
import Introduction from './components/Introduction';

const HOME_PAGE_QUERY = gql`
  query HomePageQuery {
    adminShop {
      id
      domain
      appHandle
      publicationId
      availableProductCount
      onboardingInfoCompleted
      onboardingCompleted
      venndrStoreName
      venndrStoreUrl
      connectedToVenndr
    }
  }
`;

const Home = () => {
  const {data, loading} = useQuery(HOME_PAGE_QUERY);

  if (loading) {
    return (
      <SkeletonPage narrowWidth>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
      </SkeletonPage>
    );
  }

  const {
    adminShop: {
      domain,
      appHandle,
      publicationId,
      availableProductCount,
      venndrStoreName,
      venndrStoreUrl,
      connectedToVenndr,
    },
  } = data;

  return (
    <Page narrowWidth>
      <Layout>
        {connectedToVenndr ? (
          <Status
            domain={domain}
            appHandle={appHandle}
            publicationId={publicationId}
            availableProductCount={availableProductCount}
          />
        ) : (
          <Introduction />
        )}

        <Layout.Section>
          <TextContainer>
            <Heading>Your Venndr account</Heading>
          </TextContainer>
        </Layout.Section>

        <Layout.Section>
          {connectedToVenndr ? (
            <AccountConnected
              venndrStoreName={venndrStoreName}
              venndrStoreUrl={venndrStoreUrl}
            />
          ) : (
            <NoAccountConnected />
          )}
        </Layout.Section>

        <Layout.Section>
          <FooterHelp>
            For more information or support{' '}
            <Link external url="https://venndr.com/support">
              click here
            </Link>.<br/> View the{' '}
            <Link external url="https://venndr.com/terms-and-conditions/">
              Venndr terms and conditions
            </Link>.
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Home;
