import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import {Onboarding, VenndrConnect} from '../sections';
import Home from '../sections/Home';
import AppProvider from './AppProvider';
import {AppProvider as PolarisAppProvider} from '@shopify/polaris';
import polarisTranslations from '@shopify/polaris/locales/en.json';
import translations from '@shopify/channels-ui/locales/en.json';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/connect"
          element={
            <PolarisAppProvider
              polaris={{i18n: polarisTranslations}}
              i18n={translations}
            >
              <VenndrConnect />
            </PolarisAppProvider>
          }
        />
        <Route path="/" element={<AppProvider />}>
          <Route index element={<Home />} />
          <Route path="onboarding" element={<Onboarding />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
